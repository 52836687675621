
.react-calendar { 
    margin: 10px;
    padding-top: 10px;
    width: 100%;
    max-width: 100%;
    background-color: white;
    color: black;
    border-radius: 20px !important;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
   }
   .react-calendar__navigation
   {
    margin-bottom: 5px !important;
   }
   .react-calendar__navigation__label{
    color: #C20B7E;
   }
   .react-calendar__month-view__weekdays
   {
    background-color: #F6E4F0;
    color: gray;
   }
   .react-calendar__navigation button {
    
    min-width: 44px;
    background:none;
    font-size: 16px;
    margin-top: 8px;
   }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    /* background: none; */
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-size: 10px;

  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: lightgray;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    /* background: #ffff76; */
    background-color: #C20B7E;
    color: white;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #6f48eb;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }


.MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
}


.MuiDataGrid-main {
    min-width: 900px !important;

}

.css-1bkmamk-MuiDataGrid-root .MuiDataGrid-row:hover {
    background-color: unset !important;
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row
{
    width: 100% !important;
    
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent
{
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    max-height: 50px !important;
    background-color: rgba(0,0,0,0.06) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    word-wrap: break-word !important;

    
    

}

@media (max-width: 900px) {
    .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent
    {
        white-space: pre-wrap !important;
    }
    
  }
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell {
    width: 100% !important;
    /* border: 1px gray solid !important; */
    max-width: none !important;
    max-height: none !important;
    
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: gray !important;
    border: none !important;
    overflow: visible !important;
}
.MuiDataGrid-virtualScrollerRenderZone{
  padding-right: 20px !important;
}
.MuiDataGrid-virtualScrollerContent
{
  position: relative !important;
  width: 100% !important;
  padding-right: 20px !important;
}

.MuiDataGrid-footerContainer{
    border: 1px solid rgba(244, 244, 244, 1) !important;
    border-radius: 5px !important;
}
.MuiDataGrid-root
{
    border: none !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner 
{
    width: 100%;
    padding-right: 20px !important;
}
.MuiDataGrid-root  .MuiDataGrid-columnHeaders  .MuiDataGrid-columnHeadersInner > div:only-child 
{ 
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 0px;
    
}

.MuiDataGrid-row > div:last-child{
    display: none;
    width: 0 !important;
}

.MuiDataGrid-columnHeaders
{
    margin-bottom: 10px !important;
    border: none !important;
}

.MuiDataGrid-root  
.MuiDataGrid-columnHeaders  
.MuiDataGrid-columnHeadersInner > div:only-child  
.MuiDataGrid-columnHeader
{
    max-width: none !important;
    width: 100% !important;
    min-width: 0 !important;
}

.MuiDataGrid-columnHeaderTitleContainer
{
    width: 100%;
    justify-content: center;
    background-color: #C20B7E;
    color: white;
    font-size: 14px;

}

.MuiDataGrid-columnHeaderTitle {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.select__menu
{
  z-index: 1000 !important;
  position: absolute;
  background-color: white  !important;
  width: auto !important;
  min-width: 100% !important;
}
/* 

#datatable .MuiBox-root {
  background-color: red !important;
}

#datatable > .MuiTableContainer-root {
  background-color: green !important;
} */

#datatable .MuiBox-root{
  background-color: orange !important;
}