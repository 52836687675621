@font-face {
    font-family: "Rubik";
    src: local("Rubik"),
     url("./assets/fonts/Rubik_Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Rubik";
    src: local("Rubik"),
     url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: bold;
}

.font-face-gm {
    font-family: "Rubik";
}

.font-face-gm {
    font-family: "Poppins";
}


body {
    width: 100%;
    height: 100vh;
    
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    font-family: Poppins;
  }

  #root {
    width: 100%;
  }